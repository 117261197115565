<template>
  <div>
    <div class="w-100  py-5">
      <div class="container">
        <b-row>
          <b-col sm="12" class="mt-3">
            <div class="left-pos d-flex align-items-center">
              <div
                class="logo d-flex mr-2"
                style="cursor: pointer;margin-right:10px"
                @click="$router.push('/')"
              >
                <b-img src="/img/logo.svg" width="50%" alt="" />
              </div>
              <div class="orgname1 d-flex align-items-center">
                {{ $t("publiceducation") }}
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <b-row>
              <b-col sm="12" md="5" class="mt-3">
                <img src="/img/footer-app.png" alt="" />
              </b-col>
              <b-col sm="12" md="7" class="mt-3 d-flex align-items-center">
                <div>
                  <h2 style="font-family:Montserrat-extrabold;" class="mb-3">
                    {{ $t("mobileApp") }}
                  </h2>
                  <span> {{ $t("AllInfoInPhone") }} </span>
                  <div class="mt-3">
                    <img
                      style="margin-right:10px"
                      class="cursor-pointer"
                      src="/img/appstore.png"
                      alt=""
                    />
                    <img
                      src="/img/googleplay.png"
                      class="cursor-pointer"
                      alt=""
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="6">
            <h3 style="font-family:Montserrat-bold;">
              {{ $t("connectwithus") }}
            </h3>
            <div class="d-flex my-1">
              <span> (71) 202 08 97 </span>
            </div>
            <div class="d-flex my-1">
              <span> info@biznesombudsman.uz </span>
            </div>
            <div class="d-flex my-1">
              <span> {{ $t("addressus") }} </span>
            </div>
            <h3 style="font-family:Montserrat-bold;">{{ $t("links") }}</h3>
            <div class="d-flex my-1">
              <span>
                1. {{ $t("forinspector") }}:
                <a href="http://tt.govcontrol.uz" class="text-white">
                  http://tt.govcontrol.uz
                </a>
              </span>
            </div>
            <div class="d-flex my-1" style="cursor:pointer">
              <img
                style="margin-right:15px"
                src="/img/facebook-icon.svg"
                alt=""
              />
              <img
                style="margin-right:15px"
                src="/img/telegram-icon.svg"
                alt=""
              />
              <img
                style="margin-right:15px"
                src="/img/instagram-icon.svg"
                alt=""
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
